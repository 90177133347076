
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import mAxiosApi from "@/api";
import { ElNotification } from 'element-plus'
import { use } from "element-plus/lib/locale";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {


    const store = useStore();
    const router = useRouter();
    const { t } = useI18n()

    const submitButton = ref<HTMLElement | null>(null);
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    const checkPhone = (value) => {
      if (value.length === 0 || value.match(phoneRegExp)) {
        return true
      }else{
        return false
      }
    }
    const freeMails = ["advalvas", "aol", "bbox", "bluewin", "email", "emailasso", "mailexcite", "francite", "gmail", "gmx", "hushmail", "icqmail", "icloud", "iiiha", "iname", "juramail", "katamail", "laposte", "libero", "mail", "mailfence", "mailo", "mailplazza", "mixmail", "me", "myway", "no-log", "openmailbox", "orange", "outlook", "peru", "laposte", "protonmail", "radio", "safe-mail", "sfr", "softhome", "tranquille", "vmail", "vivaldi", "webmail", "webmails", "web", "yahoo", "yandex", "zoho", "caramail", "hotmail", "cyberposte", "inmano", "lavache", "mailoo", "netcourrier"]
    const checkFreeMail = (value) => {
      let isMailFree = false
      freeMails.forEach(freeMail => {
        if (value.includes(`@${freeMail}.`)) {
          isMailFree = true
        }
      })
      return !isMailFree
    }

    const registration = Yup.object().shape({
      name: Yup.string().required(t('Le prénom est requis')),
      surname: Yup.string().required(t('Le nom est requis')),
      phone: Yup.string().test(t("Numéro valide"), t("Numéro non valide"), value => value ? checkPhone(value) : true),
      email: Yup.string().min(4, t("L'email doit contenir au moins 4 caractères")).email(t("Email non valide")).required(t("L'email est requis")).test(t("Email valide"), t("Veuillez utiliser une adresse professionnelle"), value => value ? checkFreeMail(value) : true),
      password: Yup.string().min(4, t("Le mot de passe doit contenir au moins 4 caractères")).required(t("Le mot de passe est requis")),
      cpassword: Yup.string()
        .min(4, t("Le mot de passe doit contenir au moins 4 caractères"))
        .required(t("Erreur lors de la confirmation du mot de passe"))
        .oneOf([Yup.ref("password"), null], t("Le mot de passe doit correspondre")),
      toc: Yup.boolean().oneOf([true]).required(t("Veuillez accepter nos conditions")),

    });

    const returnBaseUrl = () => {
      return process.env.VUE_APP_BACK_URL+ "/connect";
    }

    const onSubmitRegister = async (values) => {
      
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      

      // Dummy delay
      setTimeout(async () => {
        // Send login request
        const newAccount = await mAxiosApi.post("/addEditUser", values);

        if(newAccount.data.results == 'error'){
          ElNotification({
            title: 'Error',
            message: newAccount.data.message,
            type: 'error',
          })
        }
        else{
          ElNotification({
            title: 'Succes',
            message: t("Votre compte a bien été crée !"),
            type: 'success',
          })
          // Go to page after successfully login
            const login = {username: values.email, password: values.password};
            //console.log(login);
              
            store.dispatch(Actions.LOGIN, login)
            .then(function () {
            router.push({ name: "board" });
  
            });
         
        }
        
         

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };


   

    return {
      registration,
      onSubmitRegister,
      submitButton,
      returnBaseUrl
    };
  },
});
